
import styled from 'styled-components'

const Desc = styled.div`
  font-size: 18px;
  line-height: 23px;
  margin: 0 auto;
  max-width: 460px;
  text-align: center;
`

export default Desc
